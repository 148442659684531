import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const Pay = () => {
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch('https://api.paystack.co/transaction/initialize', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer sk_test_f7b25415ddebaaed6166f1d900b9c97acf69b0dc',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amount,
                    email: email
                })
            });
    
            const responseData = await response.json();
            console.log('Paystack Response:', responseData); // Log the entire response
    
            // Check if status is true and authorization_url is present
            if (responseData.status && responseData.data && responseData.data.authorization_url) {
                let redirectUrl = responseData.data.authorization_url;
                // Check if the URL is absolute
                if (!redirectUrl.startsWith('http')) {
                    // If not absolute, construct the absolute URL
                    redirectUrl = 'https://checkout.paystack.com/' + redirectUrl;
                }
                // Redirect the user after a delay of 3 seconds (3000 milliseconds)
                setTimeout(() => {
                    window.location.href = redirectUrl;
                }, 3000);
                console.log('Redirecting to:', redirectUrl);
            } else {
                console.log('Status:', responseData.status);
                console.log('Authorization URL:', responseData.data ? responseData.data.authorization_url : 'Not found');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    
   

    return (
        <div className="w-full flex flex-col gap-2 rounded-lg bg-white p-3">
            <p className="font-semibold">
            Pay with Paystack
            </p>

            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                    <label htmlFor="" className="font-semibold">
                        Email:
                    </label>

                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="border border-black px-2 py-2 rounded outline-0"
                        placeholder="vibonon947@goulink.com"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="" className="font-semibold">
                        Amount:
                    </label>

                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                        className="border border-black px-2 py-2 rounded outline-0"
                        placeholder="N 5,000"
                    />
                </div>
    
                <button type="submit" className='mt-3 mb-2 text-[#069125] border border-[#069125] font-medium p-2 px-4 rounded
                hover:bg-[#069125] hover:text-white w-1/3'>
                    Submit
                </button>
                
            </form>
        </div>
    )
}

export default Pay;
