const data = [
  {
    Plan: "Basic Plan",  
    Price: "100 naira daily",
    Feature: [
    "Access to basic health care resources", 
    "Access to text based consultation", 
  ]
  },
  {
    Plan: "Silver Plan (coming soon)",
    Price: "500 naira monthly",
    Feature: [
    "Access to basic health care resources", 
    "Access to text based consultation", 
    "Access to SMS based consultations", 
  ]
  },
  {
    Plan: "Premium Plan (coming soon)",
    Price: "1500 nairia monthly",
    Feature: [
    "Access to basic health care resources", 
    "Access to text based consultation ", 
    "Access to SMS based consultations", 
    "⁠Access to call based consultations", 
  ]
  },
  
];

export default data;
