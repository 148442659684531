import React from 'react'
import Group from '../../images/cartoon-four.jpg'
import One from '../../images/Doctor-1.png'
import Two from '../../images/Doctor-2.png'
import Three from '../../images/Doctor-3.png'
import Four from '../../images/Doctor-4.png'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (
        <div className='w-full p-4
        md:px-8 lg:px-16'>
            <div className='w-full flex flex-col gap-4
            lg:flex-row lg:items-center justify-between'>
                <div  className='w-full flex flex-col gap-2 items-start'>
                    <p className='text-2xl font-bold tracking-wider text-[#262323]
                    md:text-4xl xl:text-5xl xl:w-[700px]'>
                    <span className='linear'>
                    TalkHealth
                    </span>
                    : Get Expert Medical Advice From Anywhere.
                    </p>

                    <p className='text-sm tracking-wider md:text-base lg:text-lg xl:w-[500px]'>
                    Skip the wait, get access to quality healthcare information via a convenient online chat
                    </p>

                    <Link to='/signup' className='bg-[#069125] text-white font-medium px-3 py-2 rounded-lg mt-2'>
                    Get Started
                    </Link>
                </div>

                <img src={Group} alt="" className='w-[500px] h-[350px] lg:w-[500px] h-[500px]'/> 
            </div>

            <div className='flex  w-full gap-2 items-center justify-center linear-bg rounded-lg p-2
            md:flex-row'>
                {/* <div className='flex flex-col items-center gap-1 linear-transit w-full border border-green-300 rounded-lg p-2
                lg:w-[500px]'>
                    <p className='text-white font-semibold rounded-lg'>
                    Our Team of Specialists
                    </p>

                    <div className='flex '>
                        <img src={One} alt="" />
                        <img src={Two} alt="" />
                        <img src={Three} alt="" />
                        <img src={Four} alt="" />
                    </div>
                </div> */}

                <div className='grid grid-cols-2 gap-3 p-2 text-white text-sm
                md:gap-6'>
                    {/* <div className='flex flex-col'>
                        <p className='text-lg font-semibold'>
                        250+
                        </p>

                        <p>
                        Qualified Doctors & Medical Specialist
                        </p>
                    </div>

                    <div className='flex flex-col'>
                        <p className='text-lg font-semibold'>
                        1.487
                        </p>

                        <p>
                        Medical Test Done for our clients
                        </p>
                    </div> */}

                    <div className='flex flex-col items-center'>
                        <p className='text-lg font-semibold'>
                        1.5k+
                        </p>

                        <p>
                        Patients around the Globe in three years
                        </p>
                    </div>

                    <div className='flex flex-col items-center'>
                        <p className='text-lg font-semibold'>
                        100%
                        </p>

                        <p>
                        Client Satisfaction
                        </p>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default Hero
