import React from 'react';
import data from "../component/data/subscription";
import MTN from "../images/mtn.png";
import Mobile from "../images/9mobile.png";
import Airtel from "../images/airtel.png";
import Pay from './Pay';
import { getToken, getUsername } from '../utils/tokenHelper';

const Subscription = () => {
    const handleSubscribeClick = (plan, price) => {
        console.log(`Plan: ${plan}, Price: ${price}`);

        const token = getToken();
        const phoneNumber = `+${getUsername()}`;
 
        console.log("Token:", token);
        console.log("Phone Number:", phoneNumber);

        if (plan === "Basic Plan") {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const raw = JSON.stringify({
                "plan_type": "daily",
                "phone_number": phoneNumber
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            console.log("Data sent:", raw);

            fetch("https://api.quickcare.ng/api/v1/user/paystack/subscribe", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log("Server response:", result);
                    if (result.data && result.data.url) {
                        window.location.href = result.data.url;
                    }
                })
                .catch((error) => console.error("Error:", error));
        }
    };

    return (
        <div className='mt-6 text-sm w-full flex flex-col gap-4'>
            <p>
                Subscribe now to gain access to premium medical consultation services and enjoy the benefits of personalized healthcare.
            </p>

            <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
                {data.map((subscription, index) => (
                    <div key={index} className='bg-white rounded-lg flex flex-col items-start h-60 justify-between'>
                        <div className='w-full bg-[#F6F6F6] px-6 py-3 rounded-t-lg flex flex-col gap-2'>
                            <p className='font-semibold'>
                                {subscription.Plan}: {subscription.Price}
                            </p>
                            <div>
                                <p className='font-semibold underline'>
                                    Features
                                </p>
                                <ul className='ml-5'>
                                    {subscription.Feature.map((feature, index) => (
                                        <li key={index} className='list-disc'>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className='px-6 py-3 flex items-center justify-center'>
                            <button 
                                className='text-[#069125] border border-[#069125] font-medium p-2 rounded 
                                hover:bg-[#069125] hover:text-white'
                                onClick={() => handleSubscribeClick(subscription.Plan, subscription.Price)}
                            >
                                Subscribe Now
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className='w-full flex flex-col gap-3 items-start mt-4'>
                <p className='font-semibold'>
                    Payment Method
                </p>

                <div className='w-full flex flex-col items-center gap-4 lg:flex-row'>
                    <div className='w-full self-start lg:w-1/2'>
                        <Pay />
                    </div>

                    <p className='text-[#069125] font-semibold'>
                        OR
                    </p>

                    <div className='w-full flex flex-col gap-4 bg-white px-6 rounded-lg lg:w-1/2 py-20'>
                        <p className='font-semibold'>
                            Pay with Airtime
                        </p>

                        <div className='w-full flex items-center justify-between'>
                            <button>
                                <img src={MTN} alt="MTN" />
                            </button>
                            <button>
                                <img src={Mobile} alt="9mobile" />
                            </button>
                            <button>
                                <img src={Airtel} alt="Airtel" />
                            </button>
                        </div>
                    </div>
                </div>

                <button className='text-[#069125] border border-[#069125] font-medium p-2 px-4 rounded
                hover:bg-[#069125] hover:text-white'>
                    Proceed
                </button>
            </div>
        </div>
    );
};

export default Subscription;
