import React from 'react'
import Navbar from '../component/landing/Navbar'
import Hero from '../component/landing/Hero'
import About from '../component/landing/About'
// import Service from '../component/landing/Service'
import Benefit from '../component/landing/Benefit'
import Testimonials from '../component/landing/Testimonials'
import ContactUs from '../component/landing/ContactUs'
import Patners from '../component/landing/Patners'
import Footer from '../component/landing/Footer'

const LandingPage = () => {
    return (
        <div className='w-full h-fit bg-white'>
            <div>
                <Navbar />
            </div>

            <div className='mt-6 w-full '>
            <Hero />
            </div>

            <div className='mt-6 w-full' id='about-us'>
            <About />
            </div>

            {/* <div className='mt-6 w-full ' id='services'>
            <Service />
            </div> */}

            <div className='mt-6 w-full ' id='benefits'>
            <Benefit />
            </div>

            <div className='mt-6 w-full ' >
            <Testimonials />
            </div>

            <div className='mt-6 w-full '>
            <ContactUs />
            </div>

            <div className='mt-6 w-full '>
            <Patners />
            </div>

            <div className='mt-6 w-full '>
            <Footer />
            </div>

            
        </div>
    )
}

export default LandingPage
