import React from 'react'
import Image from '../../images/better-option.jpg'
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className='w-full flex flex-col gap-4 mt-10
        lg:flex-row p-4  lg:items-start lg:justify-between
        md:px-8 lg:px-16 '>
          <img src={Image} alt="" className='lg:w-1/2 lg:h-[300px]'/> 

          <div className='flex flex-col gap-2 lg:w-1/2
           lg:ml-6 lg:gap-3 items-center lg:items-start'>
            <p className='text-xl text-[#069125] font-bold tracking-wider'>
            ABOUT US
            </p>

            <p className='text-2xl font-semibold tracking-wider text-center lg:text-start lg:text-4xl'>
            Improving Access to Quality Healthcare
            </p>

            <p className='text-lg text-center lg:text-start'>
            Quickcare is committed to democratizing access to reliable healthcare information.
            </p>

            {/* <div className='grid grid-cols-2 p-1 w-full text-xs text-[#069125]
            md:text-sm lg:mt-4 gap-2'>
                <div className='flex items-center gap-1 '>
                    <CheckCircleOutlineIcon />

                    <p className='text-black'>
                    24/7 Medical Emergency
                    </p>
                </div>

                <div className='flex items-center gap-1'>
                    <CheckCircleOutlineIcon />

                    <p className='text-black'>
                    24/7 Medical Emergency
                    </p>
                </div>

                <div className='flex items-center gap-1'>
                    <CheckCircleOutlineIcon />

                    <p className='text-black'>
                    24/7 Medical Emergency
                    </p>
                </div>

                <div className='flex items-center gap-1'>
                    <CheckCircleOutlineIcon />

                    <p className='text-black'>
                    24/7 Medical Emergency
                    </p>
                </div>
            </div> */}
 
            <Link to='/signup' className='bg-[#069125] flex items-center justify-center text-white font-medium px-3 py-2 rounded-lg mt-2 w-1/2 md:w-1/3
            lg:mt-4'>
            Discover More
            </Link>
          </div> 
        </div>
    )
}

export default About
